@font-face {
  font-family: "Eurostile";
  src: url("./fonts/Eurostile/EurostileRegular.ttf") format("truetype");
  src: url("./fonts/Eurostile/EurostileRegular.woff") format("woff");
}

@font-face {
  font-family: "EurostileBold";
  src: url("./fonts/Eurostile/EurostileBold.ttf") format("truetype");
  src: url("./fonts/Eurostile/EurostileBold.woff") format("woff");
}

@font-face {
  font-family: "HelveticaLight";
  src: url("./fonts/Helvetica/HelveticaLight.ttf") format("truetype");
  src: url("./fonts/Helvetica/HelveticaLight.woff") format("woff");
}

@font-face {
  font-family: "Helvetica";
  src: url("./fonts/Helvetica/HelveticaRegular.ttf") format("truetype");
  src: url("./fonts/Helvetica/HelveticaRegular.woff") format("woff");
}

@font-face {
  font-family: "HelveticaBold";
  src: url("./fonts/Helvetica/HelveticaBold.ttf") format("truetype");
  src: url("./fonts/Helvetica/HelveticaBold.woff") format("woff");
}
